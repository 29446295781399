<template>
  <div>
    <span :key="'item-' + item.id" class="dossier-list--round-item" v-for="item in items" :style="{ backgroundColor: '#' + item.color }"></span>
  </div>
</template>

<script>
import Color from 'color';

export default {
  name: 'dossier-lists-round-inline',
  props: {
    ids: {
      type: Array,
      required: true
    }
  },
  computed: {
    items() {
      const dossierLists = this.$store.state.dossier_lists.items || [];
      return this.ids.map((v) => dossierLists.find((i) => i.id === v)).filter((v) => !!v);
    }
  },
  mounted() {},
  methods: {
    getFontColor(item) {
      let color = Color('#' + item.color),
        isLight = color.light();
      return isLight ? '#000' : '#fff';
    }
  }
};
</script>

<style lang="stylus">
.dossier-list--round-item {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  margin-right: 0.5rem;
  border-radius: 50%;
}
</style>

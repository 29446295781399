<template>
  <div class="video-info-form">
    <el-form v-if="uploadingItem" v-bind="formProps">
      <el-form-item :label="$tf('upload_progress')">
        <common-progress :value="progressPercentInt"></common-progress>
      </el-form-item>

      <el-form-item>
        <el-button @click="$emit('cancelUpload')">{{ $tf('cancel') }}</el-button>
      </el-form-item>
    </el-form>

    <el-form v-else ref="form" v-bind="formProps" v-loading="loading" :disabled="disabled">
      <el-form-item :label="$tf('id')" v-if="item.id">
        <span name="id">{{ item.id }}</span>
      </el-form-item>

      <el-form-item :label="$tf('name')" prop="name">
        <el-input name="name" v-model="item.name"></el-input>
      </el-form-item>

      <el-form-item :label="$tf('url')" v-if="item.id && item.url">
        <span>{{ item.url }}</span>
      </el-form-item>

      <el-form-item :label="$tf('file')" v-if="item.file_size">
        <span>{{ $tf('file') }} {{ $filters.bytesToKb(item.file_size) }}</span>
      </el-form-item>

      <template v-if="!uploaded">
        <el-form-item :label="$tfo('file | or | url')" prop="source" ref="sourceField">
          <input name="file" ref="file" type="file" @change="selectFile" style="display: none" />
          <div>
            <el-input name="url" :placeholder="$tf('url')" v-model="item.url" :disabled="!create" style="width: 16rem" @change="urlHandler"></el-input>
            <span class="input--text-info">{{ $tt('or') }}</span>
            <el-button name="file-btn" @click="$refs.file.click()" :disabled="uploaded">{{ $tf('browse | file') }}</el-button>
          </div>
          <div class="parameter-description">{{ sourceFileName }}</div>
          <div class="parameter-description">{{ $tf('drop_file_here') }}</div>
          <div class="parameter-description">{{ $tf('supported_media_formats') }}</div>
        </el-form-item>

        <el-form-item>
          <el-button size="large" :disabled="uploadDisabled" @click="upload">{{ $tf('upload') }}</el-button>
        </el-form-item>
      </template>
    </el-form>
  </div>
</template>
<script>
import Component from 'vue-class-component';
import _ from '@/apps/common/lodash';
import CommonProgress from '../../common/progress';

const sourceValidator = function (rules, value, cb) {
  const valid = this.create ? this.hasSource : true;
  cb(valid ? undefined : this.$tf('error.required.field'));
};

const baseRules = {
  name: [{ required: true, message: 'error.required.field' }],
  source: [{ required: true, validator: null }]
};

@Component({
  name: 'video-info-form',
  components: { CommonProgress },
  props: {
    item: {
      type: Object,
      required: true
    },
    loading: {
      type: Boolean,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
})
export default class VideoInfoForm extends Component {
  get progressPercentInt() {
    const progress = this.uploadingItem?.item?.progress ?? 0;
    return Math.round(progress * 100);
  }

  get uploadingItem() {
    const { id } = this.item;
    return this.$store.state.videos.uploading[id];
  }

  get formProps() {
    return this.$form.getFormProps(this.$options, this.rules, this.item);
  }

  get uploadDisabled() {
    return this.uploaded || !this.hasSource;
  }

  get uploaded() {
    return !!(this.item.id && (this.item.url || this.item.file_size));
  }

  get hasSource() {
    return this.item.source_file || this.item.url;
  }

  get create() {
    return !this.item.id;
  }

  get sourceFileName() {
    return this.item.source_file && this.item.source_file.name;
  }

  get rules() {
    const rules = _.cloneDeep(baseRules);
    rules.source[0].validator = sourceValidator.bind(this);
    return this.$applyRuleMessages(rules);
  }

  mounted() {
    this.$store.state.app.fileHandler = (files) => this.selectFile({ target: { files } });
  }

  beforeDestroy() {
    this.$store.state.app.fileHandler = null;
  }

  selectFile(e) {
    let file = e.target.files && e.target.files[0];
    this.$set(this.item, 'source_file', file);
    this.item.url = '';
    if (!this.item.name) this.item.name = file.name;
    this.$refs.file.value = null;
    this.$refs.sourceField.resetField();
  }

  urlHandler(e) {
    this.item.source_file = null;
  }

  async upload() {
    const result = await this.$refs.form.validate();

    if (result) {
      this.$emit('save');
    }
  }
}
</script>

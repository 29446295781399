<template>
  <div class="video-processing-form">
    <div class="player-container">
      <div class="player-content">
        <camera-player v-if="item.active" :id="item.id" :model="'videos'"></camera-player>
        <div v-else class="preview" :style="previewStyle"></div>
      </div>

      <div class="player-subcontent">
        <common-progress v-if="item.active" :value="Math.round((item.progress || 0) * 100)"></common-progress>
        <div v-if="item.active" class="parameter-description">
          {{ '[' + item.health_status.status + '] ' + item.health_status.msg }}
        </div>
      </div>

      <el-form v-bind="$form.props" class="player-controls" :disabled="disabled">
        <div class="player-controls__buttons">
          <el-button v-if="!item.active" type="info" @click="$emit('process')" :disabled="startProcessingDisabled">
            {{ $tf('process') }}
          </el-button>
          <el-button v-else type="info" @click="$emit('stop')" :disabled="stopProcessingDisabled">
            {{ $tf('stop') }}
          </el-button>
        </div>

        <el-form-item v-if="!item.active" :label="$tfo('processing_speed')" :disabled="changeDisabled" class="player_controls__speed">
          <el-select name="play_speed" v-model="item.stream_settings.play_speed">
            <el-option v-for="item in playSpeedItems" :key="'item-' + item.id" :label="getItemLabel(item)" :value="item.id"></el-option>
          </el-select>
        </el-form-item>

        <div class="parameter-description flex-cell">
          {{ item.active ? $tf('stop_video_processing_warning') : '' }}
        </div>

        <div v-if="!item.active" class="flex-cell--static cols">
          <div class="status">
            <div class="status-name">{{ $tf('status') }}:</div>
            <div class="status-value">{{ $tf(item.finished ? 'finished' : 'no') }}</div>
          </div>
          <div class="status">
            <div class="status-name">{{ $tf('objects') }}</div>
            <div class="status-value" v-for="countItem in objectsCountItems">{{ $tf(countItem.i18n) }}: {{ countItem.value }}</div>
          </div>
        </div>

        <div v-if="item.active" class="flex-cell--static cols">
          <div class="status">
            <div class="status-name">{{ $tf('time') }}:</div>
            <div class="status-value">{{ $filters.formatTimeMMSS(new Date(statistic.processed_duration * 1000)) }}</div>
          </div>
          <div class="status">
            <div class="status-name">{{ $tf('posted,,3') }}:</div>
            <div class="status-value">{{ statistic.faces_posted }}</div>
          </div>
          <div class="status">
            <div class="status-name">{{ $tf('errors') }}:</div>
            <div class="status-value">{{ statistic.faces_failed }}</div>
          </div>
        </div>
      </el-form>
    </div>

    <div class="episodes-container">
      <div class="episodes-filter">
        <el-form v-bind="$form.props">
          <el-form-item :label="$tf('matches,, 1')">
            <el-select name="no-match" v-model="episodeFilter.no_match" placeholder="">
              <el-option value="" :label="$tf('all')"></el-option>
              <el-option value="True" :label="$tfo(['only_without_matches'])"></el-option>
              <el-option value="False" :label="$tfo(['only_with_matches'])"></el-option>
            </el-select>
          </el-form-item>
        </el-form>

        <div class="episodes-link">
          <router-link :to="{ path: episodesPath }"
            ><i class="el-icon-search" /> <span>{{ $tf('episodes') }}</span></router-link
          >
        </div>
      </div>

      <div v-loading="$store.state.episodes.loading" class="episodes height-100">
        <div v-if="$store.state.episodes.videoItems.length">
          <episode-item :key="'episode-' + item.id" :item="item" v-for="item in $store.state.episodes.videoItems" :show-camera-metadata="false"></episode-item>
        </div>
        <div v-else class="cols--center">
          <div>{{ $tf('no_data') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import CameraStatus from '@/components/cameras/camera-status.vue';
import CameraPlayer from '@/components/cameras/player';
import EpisodeItem from '@/components/episodes/item';
import CommonProgress from '../../common/progress';
import { ObjectsTypeSingleForm } from '@/store/objects/get.module';

const PlaySpeedItems = [
  { id: -1, i18n: 'max,,1' },
  { id: 0.5, label: '0.5x' },
  { id: 1, label: '1x' },
  { id: 2, label: '2x' },
  { id: 5, label: '5x' },
  { id: 10, label: '10x' }
];

@Component({
  name: 'video-processing-form',
  components: {
    CommonProgress,
    CameraPlayer,
    CameraStatus,
    EpisodeItem
  },
  props: {
    item: {
      type: Object,
      required: true
    },
    formContent: {
      type: Object,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }
})
export default class VideoProcessingForm extends Component {
  loading = false;

  get playSpeedItems() {
    return PlaySpeedItems;
  }

  get state() {
    return this.$store.state.videos;
  }

  get episodeFilter() {
    return this.$store.state.episodes.filter.video;
  }

  get statistic() {
    return this.item.health_status.statistic;
  }

  get changeDisabled() {
    return false;
  }

  get episodesPath() {
    return `/episodes/filter/limit=10&video_archive=${this.item.id}`;
  }

  get startProcessingDisabled() {
    return this.item.active || this.$hasNoPermission('ffsecurity.change_videoarchive');
  }

  get stopProcessingDisabled() {
    return !this.item.active || this.$hasNoPermission('ffsecurity.change_videoarchive');
  }

  get previewStyle() {
    return {
      backgroundImage: `url(${this.formContent.image})`
    };
  }

  get objectsCountItems() {
    return this.$store.getters.enabledObjects
      .map((v) => {
        const value = this.item[`${ObjectsTypeSingleForm[v]}_count`],
          i18n = v;
        return { i18n, value };
      })
      .filter((i) => typeof i.value === 'number');
  }

  @Watch('item.stream_settings.play_speed')
  changePlaySpeed() {
    this.$emit('update');
  }

  @Watch('episodeFilter', { deep: true })
  changeEpisodeFilter() {
    this.$emit('getEpisodes');
  }

  getItemLabel(item) {
    return item.i18n ? this.$tf(item.i18n) : item.label;
  }

  mounted() {
    this.$emit('getEpisodes');
  }
}
</script>

<style lang="stylus">
.video-processing-form {
  display: flex;
  height: 100%;

  > .player-container {
    flex-grow: 1 1;

    .el-form {
      min-width: 640px !important;
    }

    > .player-content {
      width: 100%;
      height: 360px;
      background-color: #000;

      .preview {
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
      }
    }

    > .player-subcontent {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    > .player-controls {
      display: flex;
      align-items: center;
      height: 5rem;
      margin-top: 1rem;
      margin-bottom: 1rem;

      > div:not(:empty) + div {
        padding-left: 1rem;
      }

      .status {
        width: 6rem;
        padding-right: 1rem;
        flex-grow: 0;
      }

      .status-name {
        font-size: 0.8rem;
        text-transform: uppercase;
      }

      .status-value {
        font-size: 0.86rem;
      }

      .player_controls__speed {
        width: 9rem;
      }
    }
  }

  > .episodes-container {
    flex-grow: 0 0;

    min-width: 520px;
    .cell--1-3 {
      flex-basis: unset;
    }
    > .episodes-filter {
      max-width: 100%;
      text-align: center;
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      .episodes-link {
        text-align: center;
        min-width: 10rem;
      }

      .el-form {
        display: inline-block;
        min-width: 10rem;
        max-width: 10rem;
        padding-bottom: 1rem;
      }
    }
  }

  .fa.fa-icon--fixed-size {
    width: 14px;
    height: 14px;
  }
}
</style>

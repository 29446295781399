<template>
  <div class="stream-player" :class="{ 'stream-player_fullscreen': fullscreen }" @dblclick.stop="toggleFullscreen">
    <video :key="'video-player'" ref="video" autoplay muted></video>
    <div class="stream-player__bbox-container" ref="bbox-container">
      <template v-for="bbox in bboxArrEl">
        <div :key="'debug-' + (bbox && bbox.trackId)" v-if="$store.state.app.debug" class="stream-player__bbox-info" :style="bbox.style">
          Track ID: <br />{{ bbox && bbox.trackId }}
        </div>
        <div :key="'bbox' + bbox.trackId" :style="bbox.style" class="stream-player__bbox-item" />
        <div :key="'meta' + bbox.trackId" v-if="bbox.dossier || hasEventFeatures(bbox.event)" :style="bbox.metaInfoStyle" class="player-meta-box">
          <template v-if="bbox.dossier">
            <div class="player-meta-box__name">{{ bbox.dossier.name }}</div>
            <dossier-lists-round-inline :ids="bbox.event.matched_lists" class="player-meta-box__lists" />
          </template>
          <features v-if="hasEventFeatures(bbox.event)" :objects-type="bbox.objectsType" :features="bbox.event.features" :show-confidence="false"/>
        </div>
      </template>
    </div>

    <div class="info" v-if="$store.state.app.debug">
      <b>{{ stateText }}</b> | {{ statText }}
    </div>

    <div class="fullscreen-button" @click.stop="toggleFullscreen">
      <div class="fullscreen-button--content">
        <i class="fa center--transform" :class="fullscreen ? 'fa-compress' : 'fa-arrows-alt'"></i>
      </div>
    </div>
  </div>
</template>

<script>
import Features from '@/components/common/features.vue';
import DossierListsRoundInline from '@/components/dossier-lists/inline.round.items';

const DefaultWidth = 640,
  DefaultHeight = 360;

const BBOXColors = {
  faces: 'green',
  cars: 'red',
  bodies: 'blue',
  match: '#32D74B',
  noMatch: '#FF375F'
};

export default {
  name: 'camera-player',
  components: {
    DossierListsRoundInline,
    Features
  },
  props: {
    id: [String, Number],
    model: {
      type: String,
      default: 'cameras'
    },
    reconnectOnClose: { type: Boolean, default: false },
    showBboxObjects: {
      type: Array,
      default() {
        return ['faces', 'bodies', 'cars'];
      }
    },
    gdpr: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    objectsNames() {
      return this.$store.getters.enabledObjects;
    }
  },
  data() {
    return {
      count: 0,
      canAppendToMediaSource: false,
      statText: '',
      stateText: 'loading',
      fullscreen: false,
      updateInterval: 0,
      openTimeout: 0,
      streamProps: {
        width: DefaultWidth,
        height: DefaultHeight,
        fps: 30
      },
      bboxArrEl: [],
      trackIdToEventMap: {},
      scaleFactor: 1
    };
  },
  watch: {
    id() {
      this.playing && this.stop();
      this.$nextTick(() => this.play());
    },
    '$store.state.ws_temp_data.last_event': function (v, p) {
      const trackId = v?.detector_params?.track?.id;
      if (trackId && this.trackIdToEventMap[trackId] !== undefined) this.trackIdToEventMap[trackId] = v;
    }
  },
  created() {
    this.playing = false;
    this.$websocket = null;
    this.$mediaSource = new MediaSource();
    this.$sourceBuffer = null;
    this.$canvasCtx = null;
    this.$video = null;
    this.$buffers = {
      video: [],
      json: []
    };
  },
  mounted() {
    window.addEventListener('keyup', this.keyupHandler);
    this.play();
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.keyupHandler);
    this.stop();
  },
  methods: {
    hasMatchedLists(event) {
      const lists = event?.matched_lists?.filter((v) => v !== -1);
      return (lists?.length || 0) > 0;
    },
    keyupHandler(e) {
      if (this.fullscreen && e.keyCode === 27) {
        this.toggleFullscreen();
      }
    },
    toggleFullscreen() {
      this.fullscreen = !this.fullscreen;
      this.$store.state.video_wall.draggable = !this.fullscreen;
      this.resizeContainer();
    },
    play() {
      this.clear();
      this.initWebSocket();
      this.initMediaSource();
      this.updateInterval = setInterval(this.showOverlay, 1000 / 31);
      this.playing = true;
    },
    stop() {
      this.playing = false;
      this.$websocket?.close();
    },
    clear() {
      if (this.$websocket) this.$websocket = this.$websocket.onclose = this.$websocket.onopen = this.$websocket.onerror = null;
      this.$mediaSource = new MediaSource();
      this.$buffers = {
        video: [],
        json: []
      };
      clearTimeout(this.openTimeout);
      clearInterval(this.updateInterval);
    },
    initWebSocket() {
      clearTimeout(this.openTimeout);
      const url = this.$store.getters.wsStreamUrlTemplate.replace('{id}', this.id).replace('{model}', this.model),
        websocket = new WebSocket(url);
      websocket.binaryType = 'arraybuffer';
      websocket.onopen = () => {
        clearTimeout(this.openTimeout);
        this.stateText = 'connected';
      };
      websocket.onclose = () => {
        this.clear();
        this.stateText = 'closed';
        if (this.playing && this.reconnectOnClose) {
          this.openTimeout = setTimeout(this.play, 1000);
        }
      };
      websocket.onerror = () => {
        this.stateText = 'error';
      };
      websocket.onmessage = (e) => this.messageHandler(e);
      this.$websocket = websocket;
    },
    initMediaSource() {
      this.$video = this.$refs.video;
      this.$video.src = URL.createObjectURL(this.$mediaSource);
      this.$mediaSource.addEventListener('sourceopen', () => {
        this.$sourceBuffer = this.$mediaSource.addSourceBuffer('video/mp4;codecs="avc1.4D4001"');
        this.$sourceBuffer.mode = 'segments';
        this.$sourceBuffer.addEventListener('updateend', () => {
          if (this.$buffers.video.length) {
            this.$sourceBuffer.appendBuffer(this.$buffers.video.shift());
          } else {
            this.canAppendToMediaSource = true;
          }
        });
        this.canAppendToMediaSource = true;
      });
    },
    computeObjectBboxesFromBufferData(v) {
      const showObjects = this.showBboxObjects.filter((item) => this.objectsNames.includes(item));
      return showObjects.reduce((m, i) => {
        if (v[i] instanceof Array) {
          v[i].forEach((r) => (r['objectsType'] = i));
        }
        return !v[i] ? m : m.concat(v[i]);
      }, []);
    },
    showOverlay() {
      let jsonData = [],
        i = 0,
        c = this.$video.currentTime;

      for (i = 0; i < this.$buffers.json.length; i++) {
        let v = this.$buffers.json[i];
        if (v.ts >= c) {
          jsonData = this.computeObjectBboxesFromBufferData(v);
          break;
        }
      }

      this.$buffers.json = this.$buffers.json.slice(i);
      this.statText =
        'Time ' +
        Math.round(c) +
        ' / b.data ' +
        Math.round(this.$buffers.json.length / 10) * 10 +
        ' / b.video ' +
        Math.round(this.$buffers.video.length / 10) * 10;
      this.resizeContainer();
      this.showBBoxes(jsonData);
    },
    resizeContainer() {
      const scaleFactorH = this.$refs.video.offsetWidth / this.streamProps.width;
      const scaleFactorV = this.$refs.video.offsetHeight / this.streamProps.height;
      if (scaleFactorH * this.streamProps.height > this.$refs.video.offsetHeight) {
        this.scaleFactor = scaleFactorV;
        this.$refs['bbox-container'].style.height = '100%';
        this.$refs['bbox-container'].style.width = this.streamProps.width * scaleFactorV + 'px';
      } else {
        this.scaleFactor = scaleFactorH;
        this.$refs['bbox-container'].style.width = '100%';
        this.$refs['bbox-container'].style.height = this.streamProps.height * scaleFactorH + 'px';
      }
    },
    showBBoxes(jsonData) {
      this.bboxArrEl = [];
      jsonData.forEach((el) => this.renderBbox(el));
    },
    getBBoxRect(bbox) {
      const { scaleFactor } = this;

      return {
        x: bbox[0] * scaleFactor,
        y: bbox[1] * scaleFactor,
        width: (bbox[2] - bbox[0]) * scaleFactor,
        height: (bbox[3] - bbox[1]) * scaleFactor
      };
    },
    getBBoxParams(bbox, isMatched, objectsType) {
      const bboxRect = this.getBBoxRect(bbox);
      const bboxBorderColor = isMatched ? BBOXColors.match : BBOXColors.noMatch;
      const isMustBlurred = objectsType === 'faces' && !isMatched && this.gdpr;
      const gdprBBoxStyle = isMustBlurred && {
        'backdrop-filter': 'blur(8px)',
        'border-radius': '1000px',
        'border-width': 0
      };
      return {
        left: `${bboxRect.x}px`,
        top: `${bboxRect.y}px`,
        width: `${bboxRect.width}px`,
        height: `${bboxRect.height}px`,
        'border-color': `${bboxBorderColor}`,
        ...gdprBBoxStyle
      };
    },
    getBBoxInfoParams(bbox) {
      const { x, y, width } = this.getBBoxRect(bbox);
      const leftCoord = x + width / 2;
      return {
        left: `${leftCoord}px`,
        top: `${y}px`
      };
    },
    getDossier(trackId) {
      const dossierId = this.trackIdToEventMap[trackId]?.matched_dossier;
      return dossierId && this.$store.state.dossiers.items.find((item) => item.id === dossierId);
    },
    getEvent(trackId) {
      return this.trackIdToEventMap[trackId];
    },
    renderBbox(el) {
      const { bbox, track_id: trackId, objectsType } = el;
      if (trackId && !this.trackIdToEventMap[trackId]) this.trackIdToEventMap[trackId] = null;

      const isMatched = this.trackIdToEventMap[trackId]?.matched;
      const bboxElParams = {
        style: this.getBBoxParams(bbox, isMatched, objectsType),
        metaInfoStyle: this.getBBoxInfoParams(bbox),
        objectsType,
        trackId,
        dossier: this.getDossier(trackId),
        event: this.getEvent(trackId)
      };

      this.bboxArrEl.push(bboxElParams);
    },
    messageHandler(event) {
      const isString = typeof event.data === 'string';

      if (isString) {
        let data = JSON.parse(event.data);
        const type = data.type;

        if (type === 'begin') {
          if (data.data && data.data.width) {
            this.streamProps = data.data;
          } else {
            console.warn('[stream-player] has no stream data information (width, height): ', data);
          }
          this.stateText = 'started';
        } else if (type === 'end') {
          this.stateText = 'ended';
          this.stop();
        } else if (type === 'frame') {
          this.$buffers.json.push(data.data);
        } else {
          console.warn('[stream-player] Error, unknown type: ', data);
        }
        return;
      }

      let data = new Uint8Array(event.data);
      if (this.canAppendToMediaSource === true) {
        this.$sourceBuffer.appendBuffer(data);
        this.canAppendToMediaSource = false;
      } else {
        this.$buffers.video.push(data);
      }

      try {
        this.$refs.video.play();
      } catch (e) {
        console.warn('[stream-player]', e);
      }
    },
    hasEventFeatures(event) {
      return Object.keys(event?.features || {}).length > 0;
    }
  }
};
</script>

<style lang="stylus">
.stream-player {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &__bbox-container {
    width: 100%
    position: relative;
    transform: translate(0,0);
  }
  &__bbox-item {
    transition: all 0.3s;
    position: absolute;
    border-width: 2px;
    border-style: solid;
    border-radius: 1000px;
  }

  &__bbox-info {
    transition: all 0.3s;
    position: absolute;
    pointer-events: none;
    top: 10px;
    left: 10px;
    font-size: 0.75rem;
    padding: 0.5rem;
    background-color: rgba(0, 0, 0, 0.7);
  }

  &_fullscreen {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    width: initial;
    height: initial;
    position: fixed;
  }

  &:hover {
    .fullscreen-button {
      opacity: 1;
    }
  }

  video, canvas {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  video {
    position: absolute;

    &:focus {
      outline-style: unset;
    }
  }

  canvas {
    pointer-events: none;
    position: absolute;
    z-index: 2;
  }

  .info {
    pointer-events: none;
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 0.5rem;
    background-color: rgba(0, 0, 0, 0.7);
  }

  .fullscreen-button {
    cursor: pointer !important;
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    opacity: 0;
    transition: opacity 0.2s;

    &--content {
      width: 3rem;
      height: 3rem;
      border-radius: 3rem;
      background-color: rgba(0, 0, 0, 0.5);

      i {
        font-size: 2rem;
      }
    }
  }
  &_blur {
    backdrop-filter: blur(8px);
    border-radius: 1000px;
    border-width: 0;
  }
}

.player-meta-box {
  position: absolute;
  width: 120px;
  min-height: 40px;
  background: #FFFFFF;
  color: black;
  transform: translate(-50%, -100%);
  margin-top: -24px;
  box-shadow: 0 100px 80px rgba(0, 0, 0, 0.07),
    0 41.7776px 33.4221px rgba(0, 0, 0, 0.0503198),
    0 22.3363px 17.869px rgba(0, 0, 0, 0.0417275),
    0 12.5216px 10.0172px rgba(0, 0, 0, 0.035),
    0 6.6501px 5.32008px rgba(0, 0, 0, 0.0282725),
    0 2.76726px 2.21381px rgba(0, 0, 0, 0.0196802);
  border-radius: 20px;
  padding: 12px 12px 24px 12px;
  font-size: .75rem;
  line-height: .75rem;
  z-index: 1000;
  transition: all 0.3s;

  &__name {
    margin-bottom: 0.5rem;
    font-weight: bold;
  }

  &__lists {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }

  &__features {
    display: flex;
    justify-content: space-between;
  }
}
</style>

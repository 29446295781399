<template>
  <div class="video-parameters-form">
    <el-form
      :size="$vars.sizes.form"
      :label-position="$vars.forms.label_position"
      :labelWidth="$vars.forms.label_width"
      ref="form"
      :name="$options.name"
      :disabled="item.active || disabled"
      :model="item"
    >
      <div class="parameter-description mar-bottom--1" v-if="multiEdit">{{ $tf('multi_edit_warning') }}</div>

      <el-form-item :label="$tf('camera_group')" prop="camera_group">
        <el-select name="camera-groups" v-model="item.camera_group" :placeholder="$tf('not_selected')">
          <el-option v-for="item in $store.state.camera_groups.items" :key="'cameragroup-' + item.id" :label="$filters.shortString(item.name)" :value="item.id">
            <el-tooltip class="tooltip-item" effect="dark" :content="item.name" placement="top" :open-delay="800">
              <span>{{ $filters.shortString(item.name) }}</span>
            </el-tooltip>
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="$tf('camera')" prop="camera">
        <camera-select v-model="item.camera" :items="$store.state.cameras.items" :group="item.camera_group" @clear="clearCamera" />
      </el-form-item>

      <template v-if="!multiEdit">
        <el-form-item :label="$tf('parameter.use_stream_timestamp__simple')" prop="use_stream_timestamp">
          <el-checkbox name="use_stream_timestamp" v-model="streamSettings.use_stream_timestamp" size="small"></el-checkbox>
          <div class="parameter-description">{{ $tf('parameter.use_stream_timestamp_desc__simple') }}</div>
        </el-form-item>

        <el-form-item :label="$tf('parameter.select_start_stream_timestamp')" prop="start_stream_timestamp">
          <el-date-picker
            name="startStreamDatetime"
            v-model="startStreamDatetime"
            type="datetime"
            align="right"
            editable
            placeholder=""
            style="width: 10rem"
            :disabled="!streamSettings.use_stream_timestamp"
          />
          <el-button size="small" @click="setStartStreamDateNow" :disabled="!streamSettings.use_stream_timestamp">{{ $tf('now') }}</el-button>
          <div class="parameter-description">{{ $tf('parameter.select_start_stream_timestamp_desc') }}</div>
        </el-form-item>
      </template>
    </el-form>
  </div>
</template>
<script>
import Component from 'vue-class-component';
import CameraSelect from './camera-select';

@Component({
  name: 'video-parameters-form',
  props: {
    item: {
      type: Object,
      required: true
    },
    defaultParameters: {
      type: Object,
      required: true
    },
    multiEdit: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  components: { CameraSelect }
})
export default class VideoParametersForm extends Component {
  get streamSettings() {
    return this.item.stream_settings;
  }

  get startStreamDatetime() {
    return new Date((this.item.stream_settings.start_stream_timestamp || 0) * 1000);
  }
  set startStreamDatetime(v) {
    this.item.stream_settings.start_stream_timestamp = Math.round(v.getTime() / 1000);
  }
  setStartStreamDateNow() {
    this.item.stream_settings.start_stream_timestamp = Math.round(new Date().getTime() / 1000);
  }
  clearCamera() {
    this.item.camera = null;
  }
}
</script>

<style lang="stylus"></style>

<template>
  <div class="common-progress">
    <div class="common-progress__content" :style="style">
      <div class="common-progress__text">{{ value }}%</div>
    </div>
  </div>
</template>

<script>
import Component from 'vue-class-component';

@Component({
  name: 'common-progress',
  props: {
    value: {
      type: Number,
      requried: true
    }
  }
})
export default class CommonProgress extends Component {
  get style() {
    return { width: `${this.value}%`, backgroundPositionX: `${this.value}%` };
  }
}
</script>

<style lang="stylus">

.common-progress {
  position: relative;
  min-width: 6rem;
  height: 1.4rem;

  &:before {
    content: ' ';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    border-radius: vars.radius.base;
    background: #333
  }

  &__content {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    text-align: right;
    background: vars.background_colors.progress;
    background-size: 200% 100%;
    border-radius: vars.radius.base;
    transition: width 0.1s ease-in-out;
  }

  &__text {
    font-size: vars.text_sizes.s;
    color: vars.text_colors.primary;
    padding-left: 4px;
    padding-right: 4px;
    display: inline-flex;
    align-items: center;
    height: 100%;
  }
}
</style>
